exports.components = {
  "component---src-layouts-customer-story-index-jsx": () => import("./../../../src/layouts/CustomerStory/index.jsx" /* webpackChunkName: "component---src-layouts-customer-story-index-jsx" */),
  "component---src-layouts-partner-index-jsx": () => import("./../../../src/layouts/Partner/index.jsx" /* webpackChunkName: "component---src-layouts-partner-index-jsx" */),
  "component---src-layouts-sanity-blog-index-jsx": () => import("./../../../src/layouts/SanityBlog/index.jsx" /* webpackChunkName: "component---src-layouts-sanity-blog-index-jsx" */),
  "component---src-layouts-sanity-support-doc-index-jsx": () => import("./../../../src/layouts/SanitySupportDoc/index.jsx" /* webpackChunkName: "component---src-layouts-sanity-support-doc-index-jsx" */),
  "component---src-mdx-pages-affiliate-layout-jsx-content-file-path-src-mdx-pages-affiliate-terms-index-mdx": () => import("./../../../src/mdxPages/affiliate/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/affiliate/terms/index.mdx" /* webpackChunkName: "component---src-mdx-pages-affiliate-layout-jsx-content-file-path-src-mdx-pages-affiliate-terms-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2008-10-06-example-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2008-10-06-example/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2008-10-06-example-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2009-03-16-farewell-beta-hello-the-new-javelin-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2009-03-16-farewell_beta_hello_the_new_javelin/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2009-03-16-farewell-beta-hello-the-new-javelin-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2009-04-08-the-value-of-integrated-solutions-for-small-business-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2009-04-08-the_value_of_integrated_solutions_for_small_business/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2009-04-08-the-value-of-integrated-solutions-for-small-business-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2009-04-15-announcing-xero-and-kashflow-integration-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2009-04-15-announcing_xero_and_kashflow_integration/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2009-04-15-announcing-xero-and-kashflow-integration-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2009-05-07-tweaks-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2009-05-07-tweaks/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2009-05-07-tweaks-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2009-07-17-freeagent-integration-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2009-07-17-freeagent_integration/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2009-07-17-freeagent-integration-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2009-07-30-email-marketing-with-mailchimp-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2009-07-30-email_marketing_with_mailchimp/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2009-07-30-email-marketing-with-mailchimp-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2009-12-03-internet-explorer-is-officially-square-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2009-12-03-internet_explorer_is_officially_square/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2009-12-03-internet-explorer-is-officially-square-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2010-02-19-quick-contact-preview-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2010-02-19-quick_contact_preview/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2010-02-19-quick-contact-preview-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2010-03-10-capsule-integrates-with-google-apps-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2010-03-10-capsule_integrates_with_google_apps/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2010-03-10-capsule-integrates-with-google-apps-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2011-02-04-capsule-contextual-gadget-for-gmail-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2011-02-04-capsule-contextual-gadget-for-gmail/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2011-02-04-capsule-contextual-gadget-for-gmail-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2011-03-01-easy-forms-for-your-website-with-wufoo-integration-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2011-03-01-easy-forms-for-your-website-with-wufoo-integration/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2011-03-01-easy-forms-for-your-website-with-wufoo-integration-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2011-06-20-speedy-date-entry-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2011-06-20-speedy-date-entry/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2011-06-20-speedy-date-entry-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2011-07-03-navigation-refresh-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2011-07-03-navigation-refresh/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2011-07-03-navigation-refresh-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2011-09-27-new-capsule-support-portal-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2011-09-27-new-capsule-support-portal/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2011-09-27-new-capsule-support-portal-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2011-10-27-capsulecrm-com-refresh-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2011-10-27-capsulecrm-com-refresh/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2011-10-27-capsulecrm-com-refresh-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2012-09-06-refined-pop-over-contact-cards-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2012-09-06-refined-pop-over-contact-cards/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2012-09-06-refined-pop-over-contact-cards-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2012-12-17-now-accepting-american-express-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2012-12-17-now-accepting-american-express/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2012-12-17-now-accepting-american-express-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2013-05-03-updated-twitter-integration-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2013-05-03-updated-twitter-integration/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2013-05-03-updated-twitter-integration-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2013-11-28-google-apps-marketplace-improvements-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2013-11-28-google-apps-marketplace-improvements/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2013-11-28-google-apps-marketplace-improvements-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2014-01-08-support-portal-redesign-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2014-01-08-support-portal-redesign/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2014-01-08-support-portal-redesign-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2014-04-18-new-gmail-gadget-coming-for-google-apps-users-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2014-04-18-new-gmail-gadget-coming-for-google-apps-users/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2014-04-18-new-gmail-gadget-coming-for-google-apps-users-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2014-08-13-2-way-sync-to-google-contacts-via-pie-sync-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2014-08-13-2way-sync-to-google-contacts-via-pie-sync/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2014-08-13-2-way-sync-to-google-contacts-via-pie-sync-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2014-09-18-hack-manchester-challenge-2014-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2014-09-18-hack-manchester-challenge-2014/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2014-09-18-hack-manchester-challenge-2014-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2015-05-20-improved-tracks-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2015-05-20-improved-tracks/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2015-05-20-improved-tracks-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2015-09-11-recent-updates-sep-2015-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2015-09-11-recent-updates-sep-2015/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2015-09-11-recent-updates-sep-2015-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2016-09-12-covering-more-support-hours-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2016-09-12-covering-more-support-hours/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2016-09-12-covering-more-support-hours-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2016-11-15-recent-updates-november-2016-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2016-11-15-recent-updates-november-2016/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2016-11-15-recent-updates-november-2016-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2017-01-20-api-v-2-release-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2017-01-20-api-v2-release/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2017-01-20-api-v-2-release-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2017-09-25-sage-one-integration-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2017-09-25-sage-one-integration/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2017-09-25-sage-one-integration-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2018-01-19-january-news-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2018-01-19-january-news/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2018-01-19-january-news-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2018-05-01-new-contacts-list-index-en-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2018-05-01-new-contacts-list/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2018-05-01-new-contacts-list-index-en-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2018-05-01-new-contacts-list-index-es-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2018-05-01-new-contacts-list/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2018-05-01-new-contacts-list-index-es-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2018-05-01-new-contacts-list-index-fr-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2018-05-01-new-contacts-list/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2018-05-01-new-contacts-list-index-fr-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2018-05-01-new-contacts-list-index-pt-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2018-05-01-new-contacts-list/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2018-05-01-new-contacts-list-index-pt-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2018-05-11-zendesk-app-update-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2018-05-11-zendesk-app-update/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2018-05-11-zendesk-app-update-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2018-08-01-recent-updates-august-2018-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2018-08-01-recent-updates-august-2018/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2018-08-01-recent-updates-august-2018-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2018-11-01-gmail-add-on-improvements-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2018-11-01-gmail-add-on-improvements/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2018-11-01-gmail-add-on-improvements-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2018-12-13-gmail-add-on-updated-assignment-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2018-12-13-gmail-add-on-updated-assignment/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2018-12-13-gmail-add-on-updated-assignment-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-01-7-zapier-integration-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2019-01-7-zapier-integration/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-01-7-zapier-integration-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-04-09-remote-working-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2019-04-09-remote-working/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-04-09-remote-working-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-04-23-how-to-implement-your-crm-successfully-part-2-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2019-04-23-how-to-implement-your-crm-successfully-part-2/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-04-23-how-to-implement-your-crm-successfully-part-2-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-04-30-how-to-implement-your-crm-successfully-part-3-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2019-04-30-how-to-implement-your-crm-successfully-part-3/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-04-30-how-to-implement-your-crm-successfully-part-3-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-05-03-boost-sales-reports-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2019-05-03-boost-sales-reports/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-05-03-boost-sales-reports-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-05-16-be-more-productive-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2019-05-16-be-more-productive/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-05-16-be-more-productive-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-05-29-do-less-with-more-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2019-05-29-do-less-with-more/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-05-29-do-less-with-more-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-06-19-gmail-add-on-capsule-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2019-06-19-gmail-add-on-capsule/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-06-19-gmail-add-on-capsule-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-07-03-quickbooks-sneak-peek-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2019-07-03-quickbooks-sneak-peek/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-07-03-quickbooks-sneak-peek-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-07-26-moving-crms-index-en-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2019-07-26-moving-crms/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-07-26-moving-crms-index-en-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-07-26-moving-crms-index-es-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2019-07-26-moving-crms/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-07-26-moving-crms-index-es-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-07-26-moving-crms-index-fr-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2019-07-26-moving-crms/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-07-26-moving-crms-index-fr-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-07-26-moving-crms-index-pt-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2019-07-26-moving-crms/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-07-26-moving-crms-index-pt-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-10-16-festival-of-marketing-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2019-10-16-festival-of-marketing/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-10-16-festival-of-marketing-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-11-28-quickbooks-online-integration-is-here-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2019-11-28-quickbooks-online-integration-is-here/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-11-28-quickbooks-online-integration-is-here-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-12-06-remote-workers-christmas-games-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2019-12-06-remote-workers-christmas-games/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-12-06-remote-workers-christmas-games-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-12-23-capsule-year-in-numbers-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2019-12-23-capsule-year-in-numbers/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2019-12-23-capsule-year-in-numbers-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2020-01-15-how-to-be-more-organized-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2020-01-15-how-to-be-more-organized/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2020-01-15-how-to-be-more-organized-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2020-01-29-win-deals-build-relationships-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2020-01-29-win-deals-build-relationships/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2020-01-29-win-deals-build-relationships-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2020-02-05-how-to-get-teams-using-crm-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2020-02-05-how-to-get-teams-using-crm/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2020-02-05-how-to-get-teams-using-crm-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2020-03-11-digital-amnesia-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2020-03-11-digital-amnesia/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2020-03-11-digital-amnesia-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2020-03-16-remote-working-challenges-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2020-03-16-remote-working-challenges/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2020-03-16-remote-working-challenges-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2020-03-19-social-distancing-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2020-03-19-social-distancing/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2020-03-19-social-distancing-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2020-04-23-key-tools-to-help-run-your-business-remotely-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2020-04-23-key-tools-to-help-run-your-business-remotely/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2020-04-23-key-tools-to-help-run-your-business-remotely-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2020-05-21-having-a-professional-video-call-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2020-05-21-having-a-professional-video-call/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2020-05-21-having-a-professional-video-call-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2020-06-22-june-product-updates-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2020-06-22-june-product-updates/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2020-06-22-june-product-updates-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2020-08-17-building-successful-sales-team-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2020-08-17-building-successful-sales-team/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2020-08-17-building-successful-sales-team-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2021-01-18-capsule-2020-what-we-shipped-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2021-01-18-capsule-2020-what-we-shipped/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2021-01-18-capsule-2020-what-we-shipped-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2021-06-10-not-so-secret-ingredient-simplicity-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2021-06-10-not-so-secret-ingredient-simplicity/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2021-06-10-not-so-secret-ingredient-simplicity-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2021-07-27-companies-move-cloud-benefits-large-businesses-index-en-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2021-07-27-companies-move-cloud-benefits-large-businesses/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2021-07-27-companies-move-cloud-benefits-large-businesses-index-en-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2021-07-27-companies-move-cloud-benefits-large-businesses-index-es-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2021-07-27-companies-move-cloud-benefits-large-businesses/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2021-07-27-companies-move-cloud-benefits-large-businesses-index-es-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2021-07-27-companies-move-cloud-benefits-large-businesses-index-fr-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2021-07-27-companies-move-cloud-benefits-large-businesses/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2021-07-27-companies-move-cloud-benefits-large-businesses-index-fr-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2021-07-27-companies-move-cloud-benefits-large-businesses-index-pt-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2021-07-27-companies-move-cloud-benefits-large-businesses/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2021-07-27-companies-move-cloud-benefits-large-businesses-index-pt-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2021-11-10-what-crm-customers-really-think-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2021-11-10-what-crm-customers-really-think/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2021-11-10-what-crm-customers-really-think-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2022-01-25-capsule-outlook-addin-latest-enhancements-index-en-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2022-01-25-capsule-outlook-addin-latest-enhancements/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2022-01-25-capsule-outlook-addin-latest-enhancements-index-en-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2022-01-25-capsule-outlook-addin-latest-enhancements-index-es-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2022-01-25-capsule-outlook-addin-latest-enhancements/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2022-01-25-capsule-outlook-addin-latest-enhancements-index-es-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2022-01-25-capsule-outlook-addin-latest-enhancements-index-fr-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2022-01-25-capsule-outlook-addin-latest-enhancements/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2022-01-25-capsule-outlook-addin-latest-enhancements-index-fr-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2022-01-25-capsule-outlook-addin-latest-enhancements-index-pt-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2022-01-25-capsule-outlook-addin-latest-enhancements/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2022-01-25-capsule-outlook-addin-latest-enhancements-index-pt-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2022-03-02-sales-email-templates-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2022-03-02-sales-email-templates/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2022-03-02-sales-email-templates-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2022-06-22-finding-balance-in-the-modern-day-sales-challenge-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2022-06-22-finding-balance-in-the-modern-day-sales-challenge/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2022-06-22-finding-balance-in-the-modern-day-sales-challenge-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2022-10-03-switch-to-cloud-based-phone-system-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2022-10-03-switch-to-cloud-based-phone-system/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2022-10-03-switch-to-cloud-based-phone-system-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-01-17-what-we-brought-you-in-2022-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2023-01-17-what-we-brought-you-in-2022/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-01-17-what-we-brought-you-in-2022-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-01-23-quickbooks-crm-integration-reviews-index-en-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2023-01-23-quickbooks-crm-integration-reviews/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-01-23-quickbooks-crm-integration-reviews-index-en-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-01-23-quickbooks-crm-integration-reviews-index-es-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2023-01-23-quickbooks-crm-integration-reviews/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-01-23-quickbooks-crm-integration-reviews-index-es-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-01-23-quickbooks-crm-integration-reviews-index-fr-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2023-01-23-quickbooks-crm-integration-reviews/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-01-23-quickbooks-crm-integration-reviews-index-fr-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-01-23-quickbooks-crm-integration-reviews-index-pt-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2023-01-23-quickbooks-crm-integration-reviews/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-01-23-quickbooks-crm-integration-reviews-index-pt-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-03-22-unlock-productivity-new-ai-content-assistant-index-en-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2023-03-22-unlock-productivity-new-ai-content-assistant/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-03-22-unlock-productivity-new-ai-content-assistant-index-en-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-03-22-unlock-productivity-new-ai-content-assistant-index-es-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2023-03-22-unlock-productivity-new-ai-content-assistant/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-03-22-unlock-productivity-new-ai-content-assistant-index-es-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-03-22-unlock-productivity-new-ai-content-assistant-index-fr-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2023-03-22-unlock-productivity-new-ai-content-assistant/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-03-22-unlock-productivity-new-ai-content-assistant-index-fr-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-03-22-unlock-productivity-new-ai-content-assistant-index-pt-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2023-03-22-unlock-productivity-new-ai-content-assistant/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-03-22-unlock-productivity-new-ai-content-assistant-index-pt-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-05-16-best-xero-crm-integrations-index-en-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2023-05-16-best-xero-crm-integrations/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-05-16-best-xero-crm-integrations-index-en-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-05-16-best-xero-crm-integrations-index-es-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2023-05-16-best-xero-crm-integrations/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-05-16-best-xero-crm-integrations-index-es-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-05-16-best-xero-crm-integrations-index-fr-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2023-05-16-best-xero-crm-integrations/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-05-16-best-xero-crm-integrations-index-fr-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-05-16-best-xero-crm-integrations-index-pt-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2023-05-16-best-xero-crm-integrations/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-05-16-best-xero-crm-integrations-index-pt-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-09-04-sales-marketing-alignment-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2023-09-04-sales-marketing-alignment/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-09-04-sales-marketing-alignment-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-12-04-references-template-index-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2023-12-04-references-template/index.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2023-12-04-references-template-index-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-01-03-capsule-2023-roundup-index-en-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2024-01-03-capsule-2023-roundup/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-01-03-capsule-2023-roundup-index-en-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-01-03-capsule-2023-roundup-index-es-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2024-01-03-capsule-2023-roundup/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-01-03-capsule-2023-roundup-index-es-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-01-03-capsule-2023-roundup-index-fr-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2024-01-03-capsule-2023-roundup/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-01-03-capsule-2023-roundup-index-fr-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-01-03-capsule-2023-roundup-index-pt-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2024-01-03-capsule-2023-roundup/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-01-03-capsule-2023-roundup-index-pt-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-01-24-sales-call-recordings-index-en-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2024-01-24-sales-call-recordings/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-01-24-sales-call-recordings-index-en-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-01-24-sales-call-recordings-index-es-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2024-01-24-sales-call-recordings/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-01-24-sales-call-recordings-index-es-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-01-24-sales-call-recordings-index-fr-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2024-01-24-sales-call-recordings/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-01-24-sales-call-recordings-index-fr-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-01-24-sales-call-recordings-index-pt-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2024-01-24-sales-call-recordings/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-01-24-sales-call-recordings-index-pt-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-02-27-email-verification-index-en-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2024-02-27-email-verification/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-02-27-email-verification-index-en-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-02-27-email-verification-index-es-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2024-02-27-email-verification/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-02-27-email-verification-index-es-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-02-27-email-verification-index-fr-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2024-02-27-email-verification/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-02-27-email-verification-index-fr-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-02-27-email-verification-index-pt-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2024-02-27-email-verification/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-02-27-email-verification-index-pt-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-03-15-endless-integration-opportunities-zapier-index-en-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2024-03-15-endless-integration-opportunities-zapier/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-03-15-endless-integration-opportunities-zapier-index-en-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-03-15-endless-integration-opportunities-zapier-index-es-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2024-03-15-endless-integration-opportunities-zapier/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-03-15-endless-integration-opportunities-zapier-index-es-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-03-22-professional-services-project-management-index-en-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2024-03-22-professional-services-project-management/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-03-22-professional-services-project-management-index-en-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-03-22-professional-services-project-management-index-es-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2024-03-22-professional-services-project-management/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-03-22-professional-services-project-management-index-es-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-03-28-seo-lead-generation-index-en-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2024-03-28-seo-lead-generation/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-03-28-seo-lead-generation-index-en-mdx" */),
  "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-03-28-seo-lead-generation-index-es-mdx": () => import("./../../../src/mdxPages/blog/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/blog/2024-03-28-seo-lead-generation/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-blog-layout-jsx-content-file-path-src-mdx-pages-blog-2024-03-28-seo-lead-generation-index-es-mdx" */),
  "component---src-mdx-pages-crm-glossary-layout-jsx-content-file-path-src-mdx-pages-crm-glossary-index-en-mdx": () => import("./../../../src/mdxPages/crm-glossary/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/crm-glossary/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-crm-glossary-layout-jsx-content-file-path-src-mdx-pages-crm-glossary-index-en-mdx" */),
  "component---src-mdx-pages-crm-glossary-layout-jsx-content-file-path-src-mdx-pages-crm-glossary-index-es-mdx": () => import("./../../../src/mdxPages/crm-glossary/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/crm-glossary/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-crm-glossary-layout-jsx-content-file-path-src-mdx-pages-crm-glossary-index-es-mdx" */),
  "component---src-mdx-pages-crm-glossary-layout-jsx-content-file-path-src-mdx-pages-crm-glossary-index-fr-mdx": () => import("./../../../src/mdxPages/crm-glossary/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/crm-glossary/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-crm-glossary-layout-jsx-content-file-path-src-mdx-pages-crm-glossary-index-fr-mdx" */),
  "component---src-mdx-pages-crm-glossary-layout-jsx-content-file-path-src-mdx-pages-crm-glossary-index-pt-mdx": () => import("./../../../src/mdxPages/crm-glossary/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/crm-glossary/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-crm-glossary-layout-jsx-content-file-path-src-mdx-pages-crm-glossary-index-pt-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-apollo-index-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/apollo/index.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-apollo-index-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-callbell-index-en-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/callbell/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-callbell-index-en-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-callbell-index-es-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/callbell/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-callbell-index-es-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-callbell-index-fr-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/callbell/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-callbell-index-fr-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-callbell-index-pt-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/callbell/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-callbell-index-pt-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-circleloop-crm-index-en-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/circleloop-crm/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-circleloop-crm-index-en-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-circleloop-crm-index-es-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/circleloop-crm/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-circleloop-crm-index-es-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-circleloop-crm-index-fr-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/circleloop-crm/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-circleloop-crm-index-fr-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-circleloop-crm-index-pt-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/circleloop-crm/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-circleloop-crm-index-pt-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-covve-crm-index-en-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/covve-crm/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-covve-crm-index-en-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-covve-crm-index-es-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/covve-crm/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-covve-crm-index-es-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-covve-crm-index-fr-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/covve-crm/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-covve-crm-index-fr-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-covve-crm-index-pt-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/covve-crm/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-covve-crm-index-pt-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-docusign-index-en-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/docusign/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-docusign-index-en-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-docusign-index-es-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/docusign/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-docusign-index-es-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-docusign-index-fr-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/docusign/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-docusign-index-fr-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-docusign-index-pt-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/docusign/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-docusign-index-pt-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-dripify-index-en-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/dripify/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-dripify-index-en-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-dripify-index-es-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/dripify/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-dripify-index-es-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-dripify-index-fr-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/dripify/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-dripify-index-fr-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-dripify-index-pt-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/dripify/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-dripify-index-pt-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-fireflies-index-en-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/fireflies/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-fireflies-index-en-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-fireflies-index-es-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/fireflies/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-fireflies-index-es-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-fireflies-index-fr-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/fireflies/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-fireflies-index-fr-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-fireflies-index-pt-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/fireflies/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-fireflies-index-pt-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-freeagent-crm-index-en-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/freeagent-crm/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-freeagent-crm-index-en-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-freeagent-crm-index-es-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/freeagent-crm/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-freeagent-crm-index-es-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-freeagent-crm-index-fr-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/freeagent-crm/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-freeagent-crm-index-fr-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-freeagent-crm-index-pt-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/freeagent-crm/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-freeagent-crm-index-pt-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-freshbooks-crm-index-en-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/freshbooks-crm/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-freshbooks-crm-index-en-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-freshbooks-crm-index-es-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/freshbooks-crm/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-freshbooks-crm-index-es-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-freshbooks-crm-index-fr-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/freshbooks-crm/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-freshbooks-crm-index-fr-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-freshbooks-crm-index-pt-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/freshbooks-crm/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-freshbooks-crm-index-pt-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-gmail-index-en-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/gmail/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-gmail-index-en-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-gmail-index-es-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/gmail/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-gmail-index-es-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-gmail-index-fr-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/gmail/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-gmail-index-fr-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-gmail-index-pt-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/gmail/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-gmail-index-pt-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-google-calendar-index-en-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/google-calendar/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-google-calendar-index-en-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-google-calendar-index-es-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/google-calendar/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-google-calendar-index-es-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-google-calendar-index-fr-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/google-calendar/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-google-calendar-index-fr-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-google-calendar-index-pt-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/google-calendar/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-google-calendar-index-pt-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-google-contacts-index-en-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/google-contacts/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-google-contacts-index-en-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-google-contacts-index-es-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/google-contacts/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-google-contacts-index-es-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-google-contacts-index-fr-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/google-contacts/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-google-contacts-index-fr-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-google-contacts-index-pt-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/google-contacts/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-google-contacts-index-pt-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-google-workspace-single-sign-on-index-en-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/google-workspace-single-sign-on/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-google-workspace-single-sign-on-index-en-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-google-workspace-single-sign-on-index-es-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/google-workspace-single-sign-on/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-google-workspace-single-sign-on-index-es-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-google-workspace-single-sign-on-index-fr-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/google-workspace-single-sign-on/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-google-workspace-single-sign-on-index-fr-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-google-workspace-single-sign-on-index-pt-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/google-workspace-single-sign-on/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-google-workspace-single-sign-on-index-pt-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-jotform-crm-index-en-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/jotform-crm/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-jotform-crm-index-en-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-jotform-crm-index-es-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/jotform-crm/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-jotform-crm-index-es-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-jotform-crm-index-fr-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/jotform-crm/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-jotform-crm-index-fr-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-jotform-crm-index-pt-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/jotform-crm/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-jotform-crm-index-pt-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-kashflow-crm-index-en-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/kashflow-crm/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-kashflow-crm-index-en-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-kashflow-crm-index-es-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/kashflow-crm/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-kashflow-crm-index-es-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-kashflow-crm-index-fr-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/kashflow-crm/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-kashflow-crm-index-fr-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-kashflow-crm-index-pt-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/kashflow-crm/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-kashflow-crm-index-pt-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-lemlist-index-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/lemlist/index.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-lemlist-index-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-linkedin-index-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/linkedin/index.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-linkedin-index-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-looker-studio-connector-crm-index-en-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/looker-studio-connector-crm/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-looker-studio-connector-crm-index-en-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-looker-studio-connector-crm-index-es-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/looker-studio-connector-crm/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-looker-studio-connector-crm-index-es-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-looker-studio-connector-crm-index-fr-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/looker-studio-connector-crm/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-looker-studio-connector-crm-index-fr-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-looker-studio-connector-crm-index-pt-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/looker-studio-connector-crm/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-looker-studio-connector-crm-index-pt-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-magical-index-en-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/magical/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-magical-index-en-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-magical-index-es-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/magical/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-magical-index-es-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-magical-index-fr-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/magical/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-magical-index-fr-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-magical-index-pt-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/magical/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-magical-index-pt-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-mailchimp-crm-index-en-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/mailchimp-crm/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-mailchimp-crm-index-en-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-mailchimp-crm-index-es-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/mailchimp-crm/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-mailchimp-crm-index-es-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-mailchimp-crm-index-fr-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/mailchimp-crm/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-mailchimp-crm-index-fr-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-mailchimp-crm-index-pt-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/mailchimp-crm/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-mailchimp-crm-index-pt-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-microsoft-365-calendar-crm-index-en-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/microsoft-365-calendar-crm/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-microsoft-365-calendar-crm-index-en-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-microsoft-365-calendar-crm-index-es-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/microsoft-365-calendar-crm/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-microsoft-365-calendar-crm-index-es-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-microsoft-365-calendar-crm-index-fr-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/microsoft-365-calendar-crm/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-microsoft-365-calendar-crm-index-fr-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-microsoft-365-calendar-crm-index-pt-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/microsoft-365-calendar-crm/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-microsoft-365-calendar-crm-index-pt-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-microsoft-365-outlook-crm-index-en-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/microsoft-365-outlook-crm/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-microsoft-365-outlook-crm-index-en-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-microsoft-365-outlook-crm-index-es-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/microsoft-365-outlook-crm/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-microsoft-365-outlook-crm-index-es-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-microsoft-365-outlook-crm-index-fr-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/microsoft-365-outlook-crm/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-microsoft-365-outlook-crm-index-fr-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-microsoft-365-outlook-crm-index-pt-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/microsoft-365-outlook-crm/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-microsoft-365-outlook-crm-index-pt-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-microsoft-365-single-sign-on-crm-index-en-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/microsoft-365-single-sign-on-crm/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-microsoft-365-single-sign-on-crm-index-en-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-microsoft-365-single-sign-on-crm-index-es-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/microsoft-365-single-sign-on-crm/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-microsoft-365-single-sign-on-crm-index-es-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-microsoft-365-single-sign-on-crm-index-fr-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/microsoft-365-single-sign-on-crm/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-microsoft-365-single-sign-on-crm-index-fr-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-microsoft-365-single-sign-on-crm-index-pt-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/microsoft-365-single-sign-on-crm/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-microsoft-365-single-sign-on-crm-index-pt-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-microsoft-teams-index-en-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/microsoft-teams/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-microsoft-teams-index-en-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-microsoft-teams-index-es-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/microsoft-teams/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-microsoft-teams-index-es-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-microsoft-teams-index-fr-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/microsoft-teams/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-microsoft-teams-index-fr-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-microsoft-teams-index-pt-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/microsoft-teams/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-microsoft-teams-index-pt-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-partnerstack-index-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/partnerstack/index.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-partnerstack-index-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-sage-crm-index-en-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/sage-crm/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-sage-crm-index-en-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-sage-crm-index-es-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/sage-crm/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-sage-crm-index-es-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-sage-crm-index-fr-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/sage-crm/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-sage-crm-index-fr-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-sage-crm-index-pt-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/sage-crm/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-sage-crm-index-pt-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-slack-index-en-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/slack/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-slack-index-en-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-slack-index-es-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/slack/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-slack-index-es-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-slack-index-fr-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/slack/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-slack-index-fr-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-slack-index-pt-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/slack/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-slack-index-pt-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-squarespace-index-en-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/squarespace/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-squarespace-index-en-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-squarespace-index-es-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/squarespace/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-squarespace-index-es-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-squarespace-index-fr-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/squarespace/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-squarespace-index-fr-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-squarespace-index-pt-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/squarespace/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-squarespace-index-pt-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-wati-index-en-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/wati/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-wati-index-en-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-wati-index-es-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/wati/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-wati-index-es-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-wati-index-fr-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/wati/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-wati-index-fr-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-wati-index-pt-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/wati/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-wati-index-pt-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-wufoo-crm-index-en-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/wufoo-crm/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-wufoo-crm-index-en-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-wufoo-crm-index-es-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/wufoo-crm/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-wufoo-crm-index-es-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-wufoo-crm-index-fr-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/wufoo-crm/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-wufoo-crm-index-fr-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-wufoo-crm-index-pt-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/wufoo-crm/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-wufoo-crm-index-pt-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-zapier-crm-index-en-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/zapier-crm/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-zapier-crm-index-en-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-zapier-crm-index-es-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/zapier-crm/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-zapier-crm-index-es-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-zapier-crm-index-fr-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/zapier-crm/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-zapier-crm-index-fr-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-zapier-crm-index-pt-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/zapier-crm/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-zapier-crm-index-pt-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-zendesk-crm-index-en-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/zendesk-crm/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-zendesk-crm-index-en-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-zendesk-crm-index-es-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/zendesk-crm/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-zendesk-crm-index-es-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-zendesk-crm-index-fr-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/zendesk-crm/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-zendesk-crm-index-fr-mdx" */),
  "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-zendesk-crm-index-pt-mdx": () => import("./../../../src/mdxPages/integrations/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/integrations/zendesk-crm/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-integrations-layout-jsx-content-file-path-src-mdx-pages-integrations-zendesk-crm-index-pt-mdx" */),
  "component---src-mdx-pages-legals-layout-jsx-content-file-path-src-mdx-pages-legals-dpa-index-mdx": () => import("./../../../src/mdxPages/legals/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/legals/dpa/index.mdx" /* webpackChunkName: "component---src-mdx-pages-legals-layout-jsx-content-file-path-src-mdx-pages-legals-dpa-index-mdx" */),
  "component---src-mdx-pages-legals-layout-jsx-content-file-path-src-mdx-pages-legals-dpa-restricted-transfers-index-mdx": () => import("./../../../src/mdxPages/legals/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/legals/dpa/restricted-transfers/index.mdx" /* webpackChunkName: "component---src-mdx-pages-legals-layout-jsx-content-file-path-src-mdx-pages-legals-dpa-restricted-transfers-index-mdx" */),
  "component---src-mdx-pages-legals-layout-jsx-content-file-path-src-mdx-pages-legals-dpa-schedule-1-index-mdx": () => import("./../../../src/mdxPages/legals/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/legals/dpa/schedule-1/index.mdx" /* webpackChunkName: "component---src-mdx-pages-legals-layout-jsx-content-file-path-src-mdx-pages-legals-dpa-schedule-1-index-mdx" */),
  "component---src-mdx-pages-legals-layout-jsx-content-file-path-src-mdx-pages-legals-dpa-schedule-2-index-mdx": () => import("./../../../src/mdxPages/legals/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/legals/dpa/schedule-2/index.mdx" /* webpackChunkName: "component---src-mdx-pages-legals-layout-jsx-content-file-path-src-mdx-pages-legals-dpa-schedule-2-index-mdx" */),
  "component---src-mdx-pages-legals-layout-jsx-content-file-path-src-mdx-pages-legals-dpa-schedule-3-index-mdx": () => import("./../../../src/mdxPages/legals/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/legals/dpa/schedule-3/index.mdx" /* webpackChunkName: "component---src-mdx-pages-legals-layout-jsx-content-file-path-src-mdx-pages-legals-dpa-schedule-3-index-mdx" */),
  "component---src-mdx-pages-legals-layout-jsx-content-file-path-src-mdx-pages-legals-dpa-sub-processors-index-mdx": () => import("./../../../src/mdxPages/legals/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/legals/dpa/sub-processors/index.mdx" /* webpackChunkName: "component---src-mdx-pages-legals-layout-jsx-content-file-path-src-mdx-pages-legals-dpa-sub-processors-index-mdx" */),
  "component---src-mdx-pages-legals-layout-jsx-content-file-path-src-mdx-pages-legals-eu-rep-addresses-index-mdx": () => import("./../../../src/mdxPages/legals/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/legals/eu-rep-addresses/index.mdx" /* webpackChunkName: "component---src-mdx-pages-legals-layout-jsx-content-file-path-src-mdx-pages-legals-eu-rep-addresses-index-mdx" */),
  "component---src-mdx-pages-legals-layout-jsx-content-file-path-src-mdx-pages-legals-gdpr-index-mdx": () => import("./../../../src/mdxPages/legals/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/legals/gdpr/index.mdx" /* webpackChunkName: "component---src-mdx-pages-legals-layout-jsx-content-file-path-src-mdx-pages-legals-gdpr-index-mdx" */),
  "component---src-mdx-pages-legals-layout-jsx-content-file-path-src-mdx-pages-legals-privacy-cookie-index-mdx": () => import("./../../../src/mdxPages/legals/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/legals/privacy/cookie/index.mdx" /* webpackChunkName: "component---src-mdx-pages-legals-layout-jsx-content-file-path-src-mdx-pages-legals-privacy-cookie-index-mdx" */),
  "component---src-mdx-pages-legals-layout-jsx-content-file-path-src-mdx-pages-legals-privacy-index-mdx": () => import("./../../../src/mdxPages/legals/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/legals/privacy/index.mdx" /* webpackChunkName: "component---src-mdx-pages-legals-layout-jsx-content-file-path-src-mdx-pages-legals-privacy-index-mdx" */),
  "component---src-mdx-pages-legals-layout-jsx-content-file-path-src-mdx-pages-legals-product-data-policy-index-mdx": () => import("./../../../src/mdxPages/legals/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/legals/product-data-policy/index.mdx" /* webpackChunkName: "component---src-mdx-pages-legals-layout-jsx-content-file-path-src-mdx-pages-legals-product-data-policy-index-mdx" */),
  "component---src-mdx-pages-legals-layout-jsx-content-file-path-src-mdx-pages-legals-referral-program-terms-and-conditions-index-mdx": () => import("./../../../src/mdxPages/legals/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/legals/referral-program-terms-and-conditions/index.mdx" /* webpackChunkName: "component---src-mdx-pages-legals-layout-jsx-content-file-path-src-mdx-pages-legals-referral-program-terms-and-conditions-index-mdx" */),
  "component---src-mdx-pages-legals-layout-jsx-content-file-path-src-mdx-pages-legals-security-index-mdx": () => import("./../../../src/mdxPages/legals/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/legals/security/index.mdx" /* webpackChunkName: "component---src-mdx-pages-legals-layout-jsx-content-file-path-src-mdx-pages-legals-security-index-mdx" */),
  "component---src-mdx-pages-legals-layout-jsx-content-file-path-src-mdx-pages-legals-terms-index-mdx": () => import("./../../../src/mdxPages/legals/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/legals/terms/index.mdx" /* webpackChunkName: "component---src-mdx-pages-legals-layout-jsx-content-file-path-src-mdx-pages-legals-terms-index-mdx" */),
  "component---src-mdx-pages-legals-layout-jsx-content-file-path-src-mdx-pages-legals-third-parties-index-mdx": () => import("./../../../src/mdxPages/legals/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/legals/third-parties/index.mdx" /* webpackChunkName: "component---src-mdx-pages-legals-layout-jsx-content-file-path-src-mdx-pages-legals-third-parties-index-mdx" */),
  "component---src-mdx-pages-one-off-layout-jsx-content-file-path-src-mdx-pages-one-off-deletion-index-en-mdx": () => import("./../../../src/mdxPages/one-off/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/one-off/deletion/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-one-off-layout-jsx-content-file-path-src-mdx-pages-one-off-deletion-index-en-mdx" */),
  "component---src-mdx-pages-one-off-layout-jsx-content-file-path-src-mdx-pages-one-off-deletion-index-es-mdx": () => import("./../../../src/mdxPages/one-off/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/one-off/deletion/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-one-off-layout-jsx-content-file-path-src-mdx-pages-one-off-deletion-index-es-mdx" */),
  "component---src-mdx-pages-one-off-layout-jsx-content-file-path-src-mdx-pages-one-off-deletion-index-fr-mdx": () => import("./../../../src/mdxPages/one-off/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/one-off/deletion/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-one-off-layout-jsx-content-file-path-src-mdx-pages-one-off-deletion-index-fr-mdx" */),
  "component---src-mdx-pages-one-off-layout-jsx-content-file-path-src-mdx-pages-one-off-deletion-index-pt-mdx": () => import("./../../../src/mdxPages/one-off/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/one-off/deletion/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-one-off-layout-jsx-content-file-path-src-mdx-pages-one-off-deletion-index-pt-mdx" */),
  "component---src-mdx-pages-one-off-layout-jsx-content-file-path-src-mdx-pages-one-off-free-crm-index-en-mdx": () => import("./../../../src/mdxPages/one-off/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/one-off/free-crm/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-one-off-layout-jsx-content-file-path-src-mdx-pages-one-off-free-crm-index-en-mdx" */),
  "component---src-mdx-pages-one-off-layout-jsx-content-file-path-src-mdx-pages-one-off-free-crm-index-es-mdx": () => import("./../../../src/mdxPages/one-off/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/one-off/free-crm/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-one-off-layout-jsx-content-file-path-src-mdx-pages-one-off-free-crm-index-es-mdx" */),
  "component---src-mdx-pages-one-off-layout-jsx-content-file-path-src-mdx-pages-one-off-free-crm-index-fr-mdx": () => import("./../../../src/mdxPages/one-off/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/one-off/free-crm/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-one-off-layout-jsx-content-file-path-src-mdx-pages-one-off-free-crm-index-fr-mdx" */),
  "component---src-mdx-pages-one-off-layout-jsx-content-file-path-src-mdx-pages-one-off-free-crm-index-pt-mdx": () => import("./../../../src/mdxPages/one-off/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/one-off/free-crm/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-one-off-layout-jsx-content-file-path-src-mdx-pages-one-off-free-crm-index-pt-mdx" */),
  "component---src-mdx-pages-one-off-layout-jsx-content-file-path-src-mdx-pages-one-off-what-is-a-crm-index-en-mdx": () => import("./../../../src/mdxPages/one-off/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/one-off/what-is-a-crm/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-one-off-layout-jsx-content-file-path-src-mdx-pages-one-off-what-is-a-crm-index-en-mdx" */),
  "component---src-mdx-pages-one-off-layout-jsx-content-file-path-src-mdx-pages-one-off-what-is-a-crm-index-es-mdx": () => import("./../../../src/mdxPages/one-off/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/one-off/what-is-a-crm/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-one-off-layout-jsx-content-file-path-src-mdx-pages-one-off-what-is-a-crm-index-es-mdx" */),
  "component---src-mdx-pages-one-off-layout-jsx-content-file-path-src-mdx-pages-one-off-what-is-a-crm-index-fr-mdx": () => import("./../../../src/mdxPages/one-off/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/one-off/what-is-a-crm/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-one-off-layout-jsx-content-file-path-src-mdx-pages-one-off-what-is-a-crm-index-fr-mdx" */),
  "component---src-mdx-pages-one-off-layout-jsx-content-file-path-src-mdx-pages-one-off-what-is-a-crm-index-pt-mdx": () => import("./../../../src/mdxPages/one-off/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/one-off/what-is-a-crm/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-one-off-layout-jsx-content-file-path-src-mdx-pages-one-off-what-is-a-crm-index-pt-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-add-custom-fields-index-en-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/add-custom-fields/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-add-custom-fields-index-en-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-add-custom-fields-index-es-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/add-custom-fields/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-add-custom-fields-index-es-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-add-custom-fields-index-fr-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/add-custom-fields/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-add-custom-fields-index-fr-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-add-custom-fields-index-pt-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/add-custom-fields/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-add-custom-fields-index-pt-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-add-new-users-professional-plan-index-en-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/add-new-users-professional-plan/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-add-new-users-professional-plan-index-en-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-add-new-users-professional-plan-index-es-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/add-new-users-professional-plan/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-add-new-users-professional-plan-index-es-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-add-new-users-professional-plan-index-fr-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/add-new-users-professional-plan/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-add-new-users-professional-plan-index-fr-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-add-new-users-professional-plan-index-pt-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/add-new-users-professional-plan/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-add-new-users-professional-plan-index-pt-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-add-new-users-teams-plan-index-en-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/add-new-users-teams-plan/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-add-new-users-teams-plan-index-en-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-add-new-users-teams-plan-index-es-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/add-new-users-teams-plan/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-add-new-users-teams-plan-index-es-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-add-new-users-teams-plan-index-fr-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/add-new-users-teams-plan/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-add-new-users-teams-plan-index-fr-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-add-new-users-teams-plan-index-pt-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/add-new-users-teams-plan/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-add-new-users-teams-plan-index-pt-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-add-your-contacts-index-en-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/add-your-contacts/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-add-your-contacts-index-en-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-add-your-contacts-index-es-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/add-your-contacts/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-add-your-contacts-index-es-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-add-your-contacts-index-fr-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/add-your-contacts/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-add-your-contacts-index-fr-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-add-your-contacts-index-pt-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/add-your-contacts/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-add-your-contacts-index-pt-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-assign-records-index-en-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/assign-records/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-assign-records-index-en-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-assign-records-index-es-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/assign-records/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-assign-records-index-es-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-assign-records-index-fr-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/assign-records/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-assign-records-index-fr-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-assign-records-index-pt-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/assign-records/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-assign-records-index-pt-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-email-sending-email-templates-index-en-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/email-sending-email-templates/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-email-sending-email-templates-index-en-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-gmail-add-on-index-en-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/gmail-add-on/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-gmail-add-on-index-en-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-gmail-add-on-index-es-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/gmail-add-on/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-gmail-add-on-index-es-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-gmail-add-on-index-fr-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/gmail-add-on/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-gmail-add-on-index-fr-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-gmail-add-on-index-pt-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/gmail-add-on/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-gmail-add-on-index-pt-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-how-to-use-workflow-automations-index-en-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/how-to-use-workflow-automations/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-how-to-use-workflow-automations-index-en-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-how-to-use-workflow-automations-index-es-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/how-to-use-workflow-automations/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-how-to-use-workflow-automations-index-es-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-how-to-use-workflow-automations-index-fr-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/how-to-use-workflow-automations/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-how-to-use-workflow-automations-index-fr-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-how-to-use-workflow-automations-index-pt-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/how-to-use-workflow-automations/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-how-to-use-workflow-automations-index-pt-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-looker-studio-setup-index-en-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/looker-studio-setup/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-looker-studio-setup-index-en-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-looker-studio-setup-index-es-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/looker-studio-setup/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-looker-studio-setup-index-es-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-looker-studio-setup-index-fr-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/looker-studio-setup/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-looker-studio-setup-index-fr-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-looker-studio-setup-index-pt-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/looker-studio-setup/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-looker-studio-setup-index-pt-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-manage-your-projects-index-en-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/manage-your-projects/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-manage-your-projects-index-en-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-manage-your-projects-index-es-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/manage-your-projects/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-manage-your-projects-index-es-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-manage-your-projects-index-fr-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/manage-your-projects/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-manage-your-projects-index-fr-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-manage-your-projects-index-pt-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/manage-your-projects/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-manage-your-projects-index-pt-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-manage-your-sales-pipeline-index-en-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/manage-your-sales-pipeline/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-manage-your-sales-pipeline-index-en-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-manage-your-sales-pipeline-index-es-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/manage-your-sales-pipeline/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-manage-your-sales-pipeline-index-es-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-manage-your-sales-pipeline-index-fr-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/manage-your-sales-pipeline/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-manage-your-sales-pipeline-index-fr-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-manage-your-sales-pipeline-index-pt-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/manage-your-sales-pipeline/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-manage-your-sales-pipeline-index-pt-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-microsoft-outlook-add-in-index-en-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/microsoft-outlook-add-in/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-microsoft-outlook-add-in-index-en-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-microsoft-outlook-add-in-index-es-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/microsoft-outlook-add-in/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-microsoft-outlook-add-in-index-es-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-microsoft-outlook-add-in-index-fr-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/microsoft-outlook-add-in/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-microsoft-outlook-add-in-index-fr-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-microsoft-outlook-add-in-index-pt-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/microsoft-outlook-add-in/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-microsoft-outlook-add-in-index-pt-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-multiple-sales-pipelines-index-en-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/multiple-sales-pipelines/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-multiple-sales-pipelines-index-en-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-multiple-sales-pipelines-index-es-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/multiple-sales-pipelines/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-multiple-sales-pipelines-index-es-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-multiple-sales-pipelines-index-fr-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/multiple-sales-pipelines/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-multiple-sales-pipelines-index-fr-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-multiple-sales-pipelines-index-pt-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/multiple-sales-pipelines/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-multiple-sales-pipelines-index-pt-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-organizing-records-index-en-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/organizing-records/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-organizing-records-index-en-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-organizing-records-index-es-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/organizing-records/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-organizing-records-index-es-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-organizing-records-index-fr-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/organizing-records/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-organizing-records-index-fr-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-organizing-records-index-pt-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/organizing-records/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-organizing-records-index-pt-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-outlook-calendar-integration-index-en-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/outlook-calendar-integration/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-outlook-calendar-integration-index-en-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-outlook-calendar-integration-index-es-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/outlook-calendar-integration/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-outlook-calendar-integration-index-es-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-outlook-calendar-integration-index-fr-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/outlook-calendar-integration/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-outlook-calendar-integration-index-fr-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-outlook-calendar-integration-index-pt-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/outlook-calendar-integration/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-outlook-calendar-integration-index-pt-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-report-breakdowns-index-en-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/report-breakdowns/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-report-breakdowns-index-en-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-report-breakdowns-index-es-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/report-breakdowns/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-report-breakdowns-index-es-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-report-breakdowns-index-fr-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/report-breakdowns/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-report-breakdowns-index-fr-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-report-breakdowns-index-pt-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/report-breakdowns/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-report-breakdowns-index-pt-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-reporting-index-en-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/reporting/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-reporting-index-en-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-reporting-index-es-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/reporting/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-reporting-index-es-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-reporting-index-fr-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/reporting/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-reporting-index-fr-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-reporting-index-pt-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/reporting/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-reporting-index-pt-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-roles-and-teams-creation-index-en-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/roles-and-teams-creation/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-roles-and-teams-creation-index-en-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-roles-and-teams-creation-index-es-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/roles-and-teams-creation/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-roles-and-teams-creation-index-es-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-roles-and-teams-creation-index-fr-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/roles-and-teams-creation/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-roles-and-teams-creation-index-fr-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-roles-and-teams-creation-index-pt-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/roles-and-teams-creation/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-roles-and-teams-creation-index-pt-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-store-your-emails-index-en-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/store-your-emails/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-store-your-emails-index-en-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-store-your-emails-index-es-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/store-your-emails/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-store-your-emails-index-es-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-store-your-emails-index-fr-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/store-your-emails/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-store-your-emails-index-fr-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-store-your-emails-index-pt-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/store-your-emails/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-store-your-emails-index-pt-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-webinar-automate-your-customer-journey-index-en-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/webinar-automate-your-customer-journey/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-webinar-automate-your-customer-journey-index-en-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-webinar-automate-your-customer-journey-index-es-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/webinar-automate-your-customer-journey/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-webinar-automate-your-customer-journey-index-es-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-webinar-automate-your-customer-journey-index-fr-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/webinar-automate-your-customer-journey/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-webinar-automate-your-customer-journey-index-fr-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-webinar-automate-your-customer-journey-index-pt-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/webinar-automate-your-customer-journey/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-webinar-automate-your-customer-journey-index-pt-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-webinar-managing-your-data-in-capsule-index-en-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/webinar-managing-your-data-in-capsule/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-webinar-managing-your-data-in-capsule-index-en-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-webinar-managing-your-data-in-capsule-index-es-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/webinar-managing-your-data-in-capsule/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-webinar-managing-your-data-in-capsule-index-es-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-webinar-managing-your-data-in-capsule-index-fr-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/webinar-managing-your-data-in-capsule/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-webinar-managing-your-data-in-capsule-index-fr-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-webinar-managing-your-data-in-capsule-index-pt-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/webinar-managing-your-data-in-capsule/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-webinar-managing-your-data-in-capsule-index-pt-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-xero-integration-setup-index-en-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/xero-integration-setup/index.en.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-xero-integration-setup-index-en-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-xero-integration-setup-index-es-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/xero-integration-setup/index.es.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-xero-integration-setup-index-es-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-xero-integration-setup-index-fr-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/xero-integration-setup/index.fr.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-xero-integration-setup-index-fr-mdx" */),
  "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-xero-integration-setup-index-pt-mdx": () => import("./../../../src/mdxPages/resources/video/layout.jsx?__contentFilePath=/home/runner/work/capsulecrm.com/capsulecrm.com/src/mdxPages/resources/video/xero-integration-setup/index.pt.mdx" /* webpackChunkName: "component---src-mdx-pages-resources-video-layout-jsx-content-file-path-src-mdx-pages-resources-video-xero-integration-setup-index-pt-mdx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-index-jsx": () => import("./../../../src/pages/about/index.jsx" /* webpackChunkName: "component---src-pages-about-index-jsx" */),
  "component---src-pages-blog-index-jsx": () => import("./../../../src/pages/blog/index.jsx" /* webpackChunkName: "component---src-pages-blog-index-jsx" */),
  "component---src-pages-campaign-accountants-crm-index-jsx": () => import("./../../../src/pages/campaign/accountants-crm/index.jsx" /* webpackChunkName: "component---src-pages-campaign-accountants-crm-index-jsx" */),
  "component---src-pages-campaign-best-crm-for-business-index-jsx": () => import("./../../../src/pages/campaign/best-crm-for-business/index.jsx" /* webpackChunkName: "component---src-pages-campaign-best-crm-for-business-index-jsx" */),
  "component---src-pages-campaign-black-friday-index-jsx": () => import("./../../../src/pages/campaign/black-friday/index.jsx" /* webpackChunkName: "component---src-pages-campaign-black-friday-index-jsx" */),
  "component---src-pages-campaign-construction-contractors-crm-index-jsx": () => import("./../../../src/pages/campaign/construction-contractors-crm/index.jsx" /* webpackChunkName: "component---src-pages-campaign-construction-contractors-crm-index-jsx" */),
  "component---src-pages-campaign-crm-guide-index-jsx": () => import("./../../../src/pages/campaign/crm-guide/index.jsx" /* webpackChunkName: "component---src-pages-campaign-crm-guide-index-jsx" */),
  "component---src-pages-campaign-g-2-index-jsx": () => import("./../../../src/pages/campaign/g2/index.jsx" /* webpackChunkName: "component---src-pages-campaign-g-2-index-jsx" */),
  "component---src-pages-campaign-getting-started-index-jsx": () => import("./../../../src/pages/campaign/getting-started/index.jsx" /* webpackChunkName: "component---src-pages-campaign-getting-started-index-jsx" */),
  "component---src-pages-campaign-hotels-hospitality-crm-index-jsx": () => import("./../../../src/pages/campaign/hotels-hospitality-crm/index.jsx" /* webpackChunkName: "component---src-pages-campaign-hotels-hospitality-crm-index-jsx" */),
  "component---src-pages-campaign-it-tech-services-crm-index-jsx": () => import("./../../../src/pages/campaign/it-tech-services-crm/index.jsx" /* webpackChunkName: "component---src-pages-campaign-it-tech-services-crm-index-jsx" */),
  "component---src-pages-campaign-outlook-crm-index-jsx": () => import("./../../../src/pages/campaign/outlook-crm/index.jsx" /* webpackChunkName: "component---src-pages-campaign-outlook-crm-index-jsx" */),
  "component---src-pages-campaign-professional-services-crm-index-jsx": () => import("./../../../src/pages/campaign/professional-services-crm/index.jsx" /* webpackChunkName: "component---src-pages-campaign-professional-services-crm-index-jsx" */),
  "component---src-pages-campaign-quickbooks-index-jsx": () => import("./../../../src/pages/campaign/quickbooks/index.jsx" /* webpackChunkName: "component---src-pages-campaign-quickbooks-index-jsx" */),
  "component---src-pages-campaign-real-estate-crm-index-jsx": () => import("./../../../src/pages/campaign/real-estate-crm/index.jsx" /* webpackChunkName: "component---src-pages-campaign-real-estate-crm-index-jsx" */),
  "component---src-pages-campaign-sales-resources-index-jsx": () => import("./../../../src/pages/campaign/sales-resources/index.jsx" /* webpackChunkName: "component---src-pages-campaign-sales-resources-index-jsx" */),
  "component---src-pages-campaign-top-10-index-jsx": () => import("./../../../src/pages/campaign/top-10/index.jsx" /* webpackChunkName: "component---src-pages-campaign-top-10-index-jsx" */),
  "component---src-pages-campaign-travel-agencies-crm-index-jsx": () => import("./../../../src/pages/campaign/travel-agencies-crm/index.jsx" /* webpackChunkName: "component---src-pages-campaign-travel-agencies-crm-index-jsx" */),
  "component---src-pages-careers-index-jsx": () => import("./../../../src/pages/careers/index.jsx" /* webpackChunkName: "component---src-pages-careers-index-jsx" */),
  "component---src-pages-contact-sales-index-jsx": () => import("./../../../src/pages/contact-sales/index.jsx" /* webpackChunkName: "component---src-pages-contact-sales-index-jsx" */),
  "component---src-pages-crm-comparisons-copper-jsx": () => import("./../../../src/pages/crm-comparisons/copper.jsx" /* webpackChunkName: "component---src-pages-crm-comparisons-copper-jsx" */),
  "component---src-pages-crm-comparisons-freshsales-jsx": () => import("./../../../src/pages/crm-comparisons/freshsales.jsx" /* webpackChunkName: "component---src-pages-crm-comparisons-freshsales-jsx" */),
  "component---src-pages-crm-comparisons-index-jsx": () => import("./../../../src/pages/crm-comparisons/index.jsx" /* webpackChunkName: "component---src-pages-crm-comparisons-index-jsx" */),
  "component---src-pages-crm-comparisons-insightly-jsx": () => import("./../../../src/pages/crm-comparisons/insightly.jsx" /* webpackChunkName: "component---src-pages-crm-comparisons-insightly-jsx" */),
  "component---src-pages-crm-comparisons-nimble-jsx": () => import("./../../../src/pages/crm-comparisons/nimble.jsx" /* webpackChunkName: "component---src-pages-crm-comparisons-nimble-jsx" */),
  "component---src-pages-crm-comparisons-pipedrive-jsx": () => import("./../../../src/pages/crm-comparisons/pipedrive.jsx" /* webpackChunkName: "component---src-pages-crm-comparisons-pipedrive-jsx" */),
  "component---src-pages-crm-comparisons-zoho-jsx": () => import("./../../../src/pages/crm-comparisons/zoho.jsx" /* webpackChunkName: "component---src-pages-crm-comparisons-zoho-jsx" */),
  "component---src-pages-crm-software-jsx": () => import("./../../../src/pages/crm-software.jsx" /* webpackChunkName: "component---src-pages-crm-software-jsx" */),
  "component---src-pages-features-ai-content-assistant-jsx": () => import("./../../../src/pages/features/ai-content-assistant.jsx" /* webpackChunkName: "component---src-pages-features-ai-content-assistant-jsx" */),
  "component---src-pages-features-communication-and-collaboration-jsx": () => import("./../../../src/pages/features/communication-and-collaboration.jsx" /* webpackChunkName: "component---src-pages-features-communication-and-collaboration-jsx" */),
  "component---src-pages-features-contact-management-software-jsx": () => import("./../../../src/pages/features/contact-management-software.jsx" /* webpackChunkName: "component---src-pages-features-contact-management-software-jsx" */),
  "component---src-pages-features-customize-capsule-jsx": () => import("./../../../src/pages/features/customize-capsule.jsx" /* webpackChunkName: "component---src-pages-features-customize-capsule-jsx" */),
  "component---src-pages-features-email-marketing-jsx": () => import("./../../../src/pages/features/email-marketing.jsx" /* webpackChunkName: "component---src-pages-features-email-marketing-jsx" */),
  "component---src-pages-features-email-templates-jsx": () => import("./../../../src/pages/features/email-templates.jsx" /* webpackChunkName: "component---src-pages-features-email-templates-jsx" */),
  "component---src-pages-features-growth-jsx": () => import("./../../../src/pages/features/growth.jsx" /* webpackChunkName: "component---src-pages-features-growth-jsx" */),
  "component---src-pages-features-index-jsx": () => import("./../../../src/pages/features/index.jsx" /* webpackChunkName: "component---src-pages-features-index-jsx" */),
  "component---src-pages-features-mobile-app-jsx": () => import("./../../../src/pages/features/mobile-app.jsx" /* webpackChunkName: "component---src-pages-features-mobile-app-jsx" */),
  "component---src-pages-features-project-management-crm-jsx": () => import("./../../../src/pages/features/project-management-crm.jsx" /* webpackChunkName: "component---src-pages-features-project-management-crm-jsx" */),
  "component---src-pages-features-sales-analytics-jsx": () => import("./../../../src/pages/features/sales-analytics.jsx" /* webpackChunkName: "component---src-pages-features-sales-analytics-jsx" */),
  "component---src-pages-features-sales-pipeline-jsx": () => import("./../../../src/pages/features/sales-pipeline.jsx" /* webpackChunkName: "component---src-pages-features-sales-pipeline-jsx" */),
  "component---src-pages-features-sales-tracking-software-jsx": () => import("./../../../src/pages/features/sales-tracking-software.jsx" /* webpackChunkName: "component---src-pages-features-sales-tracking-software-jsx" */),
  "component---src-pages-features-security-and-permissions-jsx": () => import("./../../../src/pages/features/security-and-permissions.jsx" /* webpackChunkName: "component---src-pages-features-security-and-permissions-jsx" */),
  "component---src-pages-features-tasks-and-calendar-jsx": () => import("./../../../src/pages/features/tasks-and-calendar.jsx" /* webpackChunkName: "component---src-pages-features-tasks-and-calendar-jsx" */),
  "component---src-pages-features-workflow-automation-jsx": () => import("./../../../src/pages/features/workflow-automation.jsx" /* webpackChunkName: "component---src-pages-features-workflow-automation-jsx" */),
  "component---src-pages-google-workspace-crm-jsx": () => import("./../../../src/pages/google-workspace-crm.jsx" /* webpackChunkName: "component---src-pages-google-workspace-crm-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-industries-crm-for-accountants-jsx": () => import("./../../../src/pages/industries/crm-for-accountants.jsx" /* webpackChunkName: "component---src-pages-industries-crm-for-accountants-jsx" */),
  "component---src-pages-industries-crm-for-architects-jsx": () => import("./../../../src/pages/industries/crm-for-architects.jsx" /* webpackChunkName: "component---src-pages-industries-crm-for-architects-jsx" */),
  "component---src-pages-industries-crm-for-auto-dealers-jsx": () => import("./../../../src/pages/industries/crm-for-auto-dealers.jsx" /* webpackChunkName: "component---src-pages-industries-crm-for-auto-dealers-jsx" */),
  "component---src-pages-industries-crm-for-construction-jsx": () => import("./../../../src/pages/industries/crm-for-construction.jsx" /* webpackChunkName: "component---src-pages-industries-crm-for-construction-jsx" */),
  "component---src-pages-industries-crm-for-consultants-jsx": () => import("./../../../src/pages/industries/crm-for-consultants.jsx" /* webpackChunkName: "component---src-pages-industries-crm-for-consultants-jsx" */),
  "component---src-pages-industries-crm-for-customer-service-jsx": () => import("./../../../src/pages/industries/crm-for-customer-service.jsx" /* webpackChunkName: "component---src-pages-industries-crm-for-customer-service-jsx" */),
  "component---src-pages-industries-crm-for-distributors-jsx": () => import("./../../../src/pages/industries/crm-for-distributors.jsx" /* webpackChunkName: "component---src-pages-industries-crm-for-distributors-jsx" */),
  "component---src-pages-industries-crm-for-field-services-jsx": () => import("./../../../src/pages/industries/crm-for-field-services.jsx" /* webpackChunkName: "component---src-pages-industries-crm-for-field-services-jsx" */),
  "component---src-pages-industries-crm-for-finance-jsx": () => import("./../../../src/pages/industries/crm-for-finance.jsx" /* webpackChunkName: "component---src-pages-industries-crm-for-finance-jsx" */),
  "component---src-pages-industries-crm-for-freelancers-jsx": () => import("./../../../src/pages/industries/crm-for-freelancers.jsx" /* webpackChunkName: "component---src-pages-industries-crm-for-freelancers-jsx" */),
  "component---src-pages-industries-crm-for-healthcare-jsx": () => import("./../../../src/pages/industries/crm-for-healthcare.jsx" /* webpackChunkName: "component---src-pages-industries-crm-for-healthcare-jsx" */),
  "component---src-pages-industries-crm-for-hotels-and-hospitality-jsx": () => import("./../../../src/pages/industries/crm-for-hotels-and-hospitality.jsx" /* webpackChunkName: "component---src-pages-industries-crm-for-hotels-and-hospitality-jsx" */),
  "component---src-pages-industries-crm-for-law-jsx": () => import("./../../../src/pages/industries/crm-for-law.jsx" /* webpackChunkName: "component---src-pages-industries-crm-for-law-jsx" */),
  "component---src-pages-industries-crm-for-manufacturers-jsx": () => import("./../../../src/pages/industries/crm-for-manufacturers.jsx" /* webpackChunkName: "component---src-pages-industries-crm-for-manufacturers-jsx" */),
  "component---src-pages-industries-crm-for-marketing-jsx": () => import("./../../../src/pages/industries/crm-for-marketing.jsx" /* webpackChunkName: "component---src-pages-industries-crm-for-marketing-jsx" */),
  "component---src-pages-industries-crm-for-medium-sized-businesses-jsx": () => import("./../../../src/pages/industries/crm-for-medium-sized-businesses.jsx" /* webpackChunkName: "component---src-pages-industries-crm-for-medium-sized-businesses-jsx" */),
  "component---src-pages-industries-crm-for-nonprofits-jsx": () => import("./../../../src/pages/industries/crm-for-nonprofits.jsx" /* webpackChunkName: "component---src-pages-industries-crm-for-nonprofits-jsx" */),
  "component---src-pages-industries-crm-for-office-365-jsx": () => import("./../../../src/pages/industries/crm-for-office-365.jsx" /* webpackChunkName: "component---src-pages-industries-crm-for-office-365-jsx" */),
  "component---src-pages-industries-crm-for-photographers-jsx": () => import("./../../../src/pages/industries/crm-for-photographers.jsx" /* webpackChunkName: "component---src-pages-industries-crm-for-photographers-jsx" */),
  "component---src-pages-industries-crm-for-private-equity-jsx": () => import("./../../../src/pages/industries/crm-for-private-equity.jsx" /* webpackChunkName: "component---src-pages-industries-crm-for-private-equity-jsx" */),
  "component---src-pages-industries-crm-for-professional-services-jsx": () => import("./../../../src/pages/industries/crm-for-professional-services.jsx" /* webpackChunkName: "component---src-pages-industries-crm-for-professional-services-jsx" */),
  "component---src-pages-industries-crm-for-property-management-jsx": () => import("./../../../src/pages/industries/crm-for-property-management.jsx" /* webpackChunkName: "component---src-pages-industries-crm-for-property-management-jsx" */),
  "component---src-pages-industries-crm-for-real-estate-jsx": () => import("./../../../src/pages/industries/crm-for-real-estate.jsx" /* webpackChunkName: "component---src-pages-industries-crm-for-real-estate-jsx" */),
  "component---src-pages-industries-crm-for-recruitment-jsx": () => import("./../../../src/pages/industries/crm-for-recruitment.jsx" /* webpackChunkName: "component---src-pages-industries-crm-for-recruitment-jsx" */),
  "component---src-pages-industries-crm-for-restaurants-jsx": () => import("./../../../src/pages/industries/crm-for-restaurants.jsx" /* webpackChunkName: "component---src-pages-industries-crm-for-restaurants-jsx" */),
  "component---src-pages-industries-crm-for-saas-jsx": () => import("./../../../src/pages/industries/crm-for-saas.jsx" /* webpackChunkName: "component---src-pages-industries-crm-for-saas-jsx" */),
  "component---src-pages-industries-crm-for-schools-jsx": () => import("./../../../src/pages/industries/crm-for-schools.jsx" /* webpackChunkName: "component---src-pages-industries-crm-for-schools-jsx" */),
  "component---src-pages-industries-crm-for-startups-jsx": () => import("./../../../src/pages/industries/crm-for-startups.jsx" /* webpackChunkName: "component---src-pages-industries-crm-for-startups-jsx" */),
  "component---src-pages-industries-crm-for-technology-services-jsx": () => import("./../../../src/pages/industries/crm-for-technology-services.jsx" /* webpackChunkName: "component---src-pages-industries-crm-for-technology-services-jsx" */),
  "component---src-pages-industries-crm-for-telemarketing-jsx": () => import("./../../../src/pages/industries/crm-for-telemarketing.jsx" /* webpackChunkName: "component---src-pages-industries-crm-for-telemarketing-jsx" */),
  "component---src-pages-industries-crm-for-travel-agencies-jsx": () => import("./../../../src/pages/industries/crm-for-travel-agencies.jsx" /* webpackChunkName: "component---src-pages-industries-crm-for-travel-agencies-jsx" */),
  "component---src-pages-industries-crm-for-wholesaleing-jsx": () => import("./../../../src/pages/industries/crm-for-wholesaleing.jsx" /* webpackChunkName: "component---src-pages-industries-crm-for-wholesaleing-jsx" */),
  "component---src-pages-industries-crm-small-business-jsx": () => import("./../../../src/pages/industries/crm-small-business.jsx" /* webpackChunkName: "component---src-pages-industries-crm-small-business-jsx" */),
  "component---src-pages-industries-index-jsx": () => import("./../../../src/pages/industries/index.jsx" /* webpackChunkName: "component---src-pages-industries-index-jsx" */),
  "component---src-pages-integrations-index-jsx": () => import("./../../../src/pages/integrations/index.jsx" /* webpackChunkName: "component---src-pages-integrations-index-jsx" */),
  "component---src-pages-integrations-quickbooks-crm-index-jsx": () => import("./../../../src/pages/integrations/quickbooks-crm/index.jsx" /* webpackChunkName: "component---src-pages-integrations-quickbooks-crm-index-jsx" */),
  "component---src-pages-integrations-transpond-index-jsx": () => import("./../../../src/pages/integrations/transpond/index.jsx" /* webpackChunkName: "component---src-pages-integrations-transpond-index-jsx" */),
  "component---src-pages-integrations-xero-crm-index-jsx": () => import("./../../../src/pages/integrations/xero-crm/index.jsx" /* webpackChunkName: "component---src-pages-integrations-xero-crm-index-jsx" */),
  "component---src-pages-invite-jsx": () => import("./../../../src/pages/invite.jsx" /* webpackChunkName: "component---src-pages-invite-jsx" */),
  "component---src-pages-media-index-jsx": () => import("./../../../src/pages/media/index.jsx" /* webpackChunkName: "component---src-pages-media-index-jsx" */),
  "component---src-pages-microsoft-365-integrations-crm-index-jsx": () => import("./../../../src/pages/microsoft-365-integrations-crm/index.jsx" /* webpackChunkName: "component---src-pages-microsoft-365-integrations-crm-index-jsx" */),
  "component---src-pages-new-campaign-top-10-index-jsx": () => import("./../../../src/pages/new-campaign/top-10/index.jsx" /* webpackChunkName: "component---src-pages-new-campaign-top-10-index-jsx" */),
  "component---src-pages-newsletter-thanks-jsx": () => import("./../../../src/pages/newsletter-thanks.jsx" /* webpackChunkName: "component---src-pages-newsletter-thanks-jsx" */),
  "component---src-pages-partner-program-affiliate-index-jsx": () => import("./../../../src/pages/partner-program/affiliate/index.jsx" /* webpackChunkName: "component---src-pages-partner-program-affiliate-index-jsx" */),
  "component---src-pages-partner-program-index-jsx": () => import("./../../../src/pages/partner-program/index.jsx" /* webpackChunkName: "component---src-pages-partner-program-index-jsx" */),
  "component---src-pages-partner-program-smb-index-jsx": () => import("./../../../src/pages/partner-program/smb/index.jsx" /* webpackChunkName: "component---src-pages-partner-program-smb-index-jsx" */),
  "component---src-pages-partner-program-solution-index-jsx": () => import("./../../../src/pages/partner-program/solution/index.jsx" /* webpackChunkName: "component---src-pages-partner-program-solution-index-jsx" */),
  "component---src-pages-partner-program-technology-index-jsx": () => import("./../../../src/pages/partner-program/technology/index.jsx" /* webpackChunkName: "component---src-pages-partner-program-technology-index-jsx" */),
  "component---src-pages-partners-1st-formations-jsx": () => import("./../../../src/pages/partners/1st-formations.jsx" /* webpackChunkName: "component---src-pages-partners-1st-formations-jsx" */),
  "component---src-pages-partners-breathe-jsx": () => import("./../../../src/pages/partners/breathe.jsx" /* webpackChunkName: "component---src-pages-partners-breathe-jsx" */),
  "component---src-pages-partners-capsule-jsx": () => import("./../../../src/pages/partners/capsule.jsx" /* webpackChunkName: "component---src-pages-partners-capsule-jsx" */),
  "component---src-pages-partners-donut-jsx": () => import("./../../../src/pages/partners/donut.jsx" /* webpackChunkName: "component---src-pages-partners-donut-jsx" */),
  "component---src-pages-partners-founderpass-jsx": () => import("./../../../src/pages/partners/founderpass.jsx" /* webpackChunkName: "component---src-pages-partners-founderpass-jsx" */),
  "component---src-pages-partners-hashtag-jsx": () => import("./../../../src/pages/partners/hashtag.jsx" /* webpackChunkName: "component---src-pages-partners-hashtag-jsx" */),
  "component---src-pages-partners-index-jsx": () => import("./../../../src/pages/partners/index.jsx" /* webpackChunkName: "component---src-pages-partners-index-jsx" */),
  "component---src-pages-partners-scrutton-bland-jsx": () => import("./../../../src/pages/partners/scrutton-bland.jsx" /* webpackChunkName: "component---src-pages-partners-scrutton-bland-jsx" */),
  "component---src-pages-partners-vk-business-consulting-ltd-jsx": () => import("./../../../src/pages/partners/vk-business-consulting-ltd.jsx" /* webpackChunkName: "component---src-pages-partners-vk-business-consulting-ltd-jsx" */),
  "component---src-pages-quickbooks-disconnected-jsx": () => import("./../../../src/pages/quickbooks-disconnected.jsx" /* webpackChunkName: "component---src-pages-quickbooks-disconnected-jsx" */),
  "component---src-pages-resources-customer-stories-index-jsx": () => import("./../../../src/pages/resources/customer-stories/index.jsx" /* webpackChunkName: "component---src-pages-resources-customer-stories-index-jsx" */),
  "component---src-pages-resources-index-jsx": () => import("./../../../src/pages/resources/index.jsx" /* webpackChunkName: "component---src-pages-resources-index-jsx" */),
  "component---src-pages-signup-index-jsx": () => import("./../../../src/pages/signup/index.jsx" /* webpackChunkName: "component---src-pages-signup-index-jsx" */),
  "component---src-pages-support-account-and-security-index-jsx": () => import("./../../../src/pages/support/account-and-security/index.jsx" /* webpackChunkName: "component---src-pages-support-account-and-security-index-jsx" */),
  "component---src-pages-support-business-insights-index-jsx": () => import("./../../../src/pages/support/business-insights/index.jsx" /* webpackChunkName: "component---src-pages-support-business-insights-index-jsx" */),
  "component---src-pages-support-calendar-and-tasks-index-jsx": () => import("./../../../src/pages/support/calendar-and-tasks/index.jsx" /* webpackChunkName: "component---src-pages-support-calendar-and-tasks-index-jsx" */),
  "component---src-pages-support-contact-management-index-jsx": () => import("./../../../src/pages/support/contact-management/index.jsx" /* webpackChunkName: "component---src-pages-support-contact-management-index-jsx" */),
  "component---src-pages-support-contact-us-jsx": () => import("./../../../src/pages/support/contact-us.jsx" /* webpackChunkName: "component---src-pages-support-contact-us-jsx" */),
  "component---src-pages-support-importing-and-migrating-data-index-jsx": () => import("./../../../src/pages/support/importing-and-migrating-data/index.jsx" /* webpackChunkName: "component---src-pages-support-importing-and-migrating-data-index-jsx" */),
  "component---src-pages-support-index-jsx": () => import("./../../../src/pages/support/index.jsx" /* webpackChunkName: "component---src-pages-support-index-jsx" */),
  "component---src-pages-support-integrations-index-jsx": () => import("./../../../src/pages/support/integrations/index.jsx" /* webpackChunkName: "component---src-pages-support-integrations-index-jsx" */),
  "component---src-pages-support-mobile-apps-index-jsx": () => import("./../../../src/pages/support/mobile-apps/index.jsx" /* webpackChunkName: "component---src-pages-support-mobile-apps-index-jsx" */),
  "component---src-pages-support-projects-and-events-index-jsx": () => import("./../../../src/pages/support/projects-and-events/index.jsx" /* webpackChunkName: "component---src-pages-support-projects-and-events-index-jsx" */),
  "component---src-pages-support-sales-index-jsx": () => import("./../../../src/pages/support/sales/index.jsx" /* webpackChunkName: "component---src-pages-support-sales-index-jsx" */),
  "component---src-pages-support-search-index-jsx": () => import("./../../../src/pages/support/search/index.jsx" /* webpackChunkName: "component---src-pages-support-search-index-jsx" */),
  "component---src-pages-support-setup-and-configuration-index-jsx": () => import("./../../../src/pages/support/setup-and-configuration/index.jsx" /* webpackChunkName: "component---src-pages-support-setup-and-configuration-index-jsx" */),
  "component---src-pages-support-workflow-automation-index-jsx": () => import("./../../../src/pages/support/workflow-automation/index.jsx" /* webpackChunkName: "component---src-pages-support-workflow-automation-index-jsx" */),
  "component---src-pages-tiers-jsx": () => import("./../../../src/pages/tiers.jsx" /* webpackChunkName: "component---src-pages-tiers-jsx" */),
  "component---src-pages-ultimate-index-jsx": () => import("./../../../src/pages/ultimate/index.jsx" /* webpackChunkName: "component---src-pages-ultimate-index-jsx" */),
  "component---src-templates-blog-category-template-jsx": () => import("./../../../src/templates/blog-category-template.jsx" /* webpackChunkName: "component---src-templates-blog-category-template-jsx" */)
}

